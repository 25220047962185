import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../images/logo-header.jpeg";
import mobile_menu from "../images/hamburger_menu.png";
import facebook_icon from "../images/facebook-white.png"
import instagram_icon from "../images/insta-white.png"
import linkedin_icon from "../images/linkedin-white.png"
import mail_icon from "../images/mail-white.png"

function Header() {
  let location = useLocation();
  let path = location.pathname;
  return (
    <div className={path === "/thank-you" ? "hidden" : "block"}>
      {/* Navbar */}
      <div id="top" className="hidden lg:block">
        <div className="bg-purple px-4 py-3 flex flex-row gap-x-5 items-center justify-end text-white font-[poppins]">
          <a href="https://www.facebook.com/womennetworkingclub" target="_blank"><img src={facebook_icon} alt="facebook_icon" className="w-[25px] h-[25px]" /></a>
          <a href="https://www.instagram.com/womennetworkingclub/" target="_blank"><img src={instagram_icon} alt="instagram_icon" className="w-[25px] h-[25px]" /></a>
          <a href="https://in.linkedin.com/company/women-networking-club" target="_blank"><img src={linkedin_icon} alt="linkedin_icon" className="w-[25px] h-[25px]" /></a>
          <a href="mailto: info@womennetworkingclub.com"><div className="flex items-center gap-x-2 ml-3">
            <img src={mail_icon} alt="mail-icon" className="w-[25px] h-[25px]" />
            <p>info@womennetworkignclub.com</p>
          </div></a>
        </div>

        <div className="px-8 py-1 flex font-[poppins] ">
          <Link to="/home">
            <img
              className="float-left"
              src={Logo}
              onClick={Top}
              width="65"
              height="65"
              alt="wnc logo"
            />
          </Link>
          <div className="text-sm text-base my-auto mx-auto">
            {/* <Link className='mx-6  px-3 pb-2 focus:border-b-2 focus:border-purple' to = '/Home'>HOME</Link> */}
            <Link
              className={
                path === "/home"
                  ? "border-b-2  border-purple mx-6  px-3 pb-2"
                  : "mx-6  px-3 pb-2 "
              }
              to="/home"
            >
              HOME
            </Link>
            <Link
              className={
                path === "/about"
                  ? "border-b-2 border-purple mx-6  px-3 pb-2"
                  : "mx-6 px-3 pb-2"
              }
              to="/about"
            >
              ABOUT US
            </Link>
            <Link className="mx-6  px-3 pb-2 " to={path ==="/event" || path ==="/event-register" ? "/event-register" :"/register"}>
              <button className={`px-3 py-1.5 bg-purple text-white rounded ${path === "/event" || path ==="/event-register" ? "!bg-yellow !text-black ": ""}`}>
                REGISTER
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* mobile view */}
      <div className="block lg:hidden">

        <div className="px-10 py-3 flex justify-between ">
          <Link to="/home">
            <img
              className="float-left"
              src={Logo}
              onClick={Top}
              width="65"
              height="65"
              alt="wnc logo"
            />
          </Link>

          <button onClick={openNav}>
            <img
              className="float-left"
              src={mobile_menu}
              width="30"
              height="30"
              alt="menu icon"
            />
          </button>
        </div>
      </div>

      <div
        id="mobileNav"
        className="fixed w-0 h-full bg-purple inset-y-0 right-0 z-20 text-white overflow-hidden duration-300 "
      >
        <button
          className=" text-4xl font-thin float-right mr-4 mt-2"
          onClick={closeNav}
        >
          &times;
        </button>

        <ul className="pt-20 pl-10 text-base">
          <li className=" py-4 mr-7 border-b border-slate" onClick={closeNav}>
            <Link to="/home">Home </Link>
          </li>
          <li className=" py-4 mr-7 border-b border-slate" onClick={closeNav}>
            <Link to="/about">About us</Link>
          </li>
          <li className="py-4 mr-7 " onClick={closeNav}>
            <Link to={path ==="/event" || path ==="/event-register" ? "/event-register" :"/register"}>Register</Link>
          </li>
        </ul>
        <ul className="flex text-lg pt-8 pl-4">
          <a
            href="https://www.facebook.com/womennetworkingclub"
            target="_blank"
            rel="noreferrer"
          >
            <li className="pl-6">
              <i className="fa fa-facebook" />
            </li>
          </a>
          <a
            href="https://www.instagram.com/womennetworkingclub/"
            target="_blank"
            rel="noreferrer"
          >
            <li className="pl-6">
              <i className="fa fa-instagram" />
            </li>
          </a>
          <a
            href="https://www.linkedin.com/company/women-networking-club/about/?viewAsMember=true"
            target="_blank"
            rel="noreferrer"
          >
            <li className="pl-6">
              <i className="fa fa-linkedin"></i>
            </li>
          </a>
          <a
            href="mailto:info@womennetworkingclub.com"
            target="_blank"
            rel="noreferrer"
          >
            <li className="pl-6">
              <i className="fa fa-envelope px-1" />
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
}

function openNav() {
  document.getElementById("mobileNav").style.width = "250px";
}

function closeNav() {
  document.getElementById("mobileNav").style.width = "0px";
}

function Top() {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}

export default Header;
