import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";

import Home from "./Pages/Home";
import About from "./Pages/About";
import Register from "./Pages/Register";
import Terms from "./Pages/Terms";
import Thankyou from "./Components/Thankyou";
import Dashboard from "./Dashboard";
import Milestone from "./Sections/Milestone";
import PastEvents from "./Sections/PastEvents";
import Verify from "./Components/Verify";
import Event_Page from "./Pages/Event_Page"
import Event_Register from "./Pages/Event_Register";

function App() {
  return (
    <div>
      <Routes>
        <Route path="*" element={<Navigate to ="/home" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/register" element={<Register />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/thank-you" element={<Thankyou />} />
         <Route path="/admin" element={<Verify />} /> 
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/milestone" element={<Milestone />} />
        <Route path="/past-events" element={<PastEvents />} />
      </Routes>
    </div>
  );
}

export default App;
