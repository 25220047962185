import React from "react";
import {Link} from 'react-router-dom'
import tick_icon from '../images/tick-icon.png'



function Thankyou () {

    return (
        <>
            <div className=" h-screen items-center justify-center bg-grey sm:flex hidden">
                <div className=" p-10 flex flex-col items-center justify-center w-1/2 h-96 border-4 border-purple bg-white rounded-xl">
                    <img className="mb-6" src={tick_icon} alt="tick-icon" />
                    <h1 className="font-[Poppins] text-4xl leading-[40px] text-center">You have successfully registered for the event!</h1>
                    <h4 className="font-[Poppins] text-lg text-center mt-4">Keep an eye on your <b>Email/Whatsapp</b> for updates</h4>
                    <Link to="/home">
                    <button className="px-5 py-3 rounded-lg mt-7 bg-purple text-white uppercase w-[150px]">Home</button>
                    </Link>
                </div>
            </div>


            <div className="h-screen bg-grey flex justify-center items-center px-3 sm:hidden ">
                <div className="bg-white border-purple border-2 flex flex-col items-center px-3 py-5">
                    <img className="mb-6" src={tick_icon} alt="tick-icon" />
                    <h1 className="font-[Poppins] text-[28px] leading-[40px] text-center">You have successfully registered for the event!</h1>
                    <h4 className="font-[Poppins] text-[14px] text-center mt-4">Keep an eye on your <b>Email/Whatsapp</b> for updates</h4>

                    <Link to={"/home"}><button className="px-5 py-3 rounded-lg mt-7 bg-purple text-white uppercase w-[150px]">Home</button></Link>
                </div>

                
            </div>

            
        </>
    )
};

export default Thankyou